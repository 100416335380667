DIV.PropertyRow {
    overflow-wrap: anywhere;
    overflow: hidden;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    flex-direction: row;
    font-size: 15px;
    font-weight: 400;
    width: 100%;
    min-height: 40px;
}

DIV.PropertyRow:hover {
    background-color: rgba(200,200,200, 0.2);
}

DIV.PropertyRow DIV.FieldName {
    font-size: 16px;
    font-weight: 500;
    max-width: 330px;
    min-width: 120px;
    display: flex;
    align-items: center;
    padding-left: 5px;
    width: 250px;
}

DIV.PropertyRow DIV.FieldValue {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 10px;
}

DIV.PropertyRow DIV.FieldName SPAN {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

DIV.PropertyRow .FieldMore {
    cursor: pointer;
    margin-left: 10px;
    padding: 2px;
    width: 24px;
    height: 24px;
    border-radius: 5px;
}

DIV.PropertyRow .FieldMore:hover {
    cursor: pointer;
    margin-left: 10px;
    background-color: rgb(0, 136, 254);
    color: white;
}


