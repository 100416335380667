.ObjectFiltering {
    padding: 5px 15px 0 15px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.ObjectFiltering > .Input {
    flex: 1;
}
.ObjectFiltering > .Date {
    width: 245px;
}
.ObjectFiltering > .Action {
    display: flex;
}