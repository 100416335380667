.EventRow {
    font-weight: 400;
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-top: 1px solid #aaa;
    animation-name: moveUpAndOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    animation-duration: .3s;
}

.EventRow:hover {
    background-color: rgba(200,200,200, 0.2);
    box-shadow: 0px 8px 14px -13px rgba(66, 68, 90, 1);
}

.EventRow .Header {
    display: flex;
    flex-direction: row;
}

.EventRow .Header > DIV {
    padding: 3px;
    line-height: 1.7em;
}

.EventRow .Header DIV.Toggle {
    padding-top: 5px;
    cursor: pointer;
    width: 30px;
}

.EventRow .Header > DIV.Timestamp {
    display: flex;
    flex-direction: column;
    padding-top: 6px;
    min-width: 220px;
    max-width: 220px;
    border-right: solid 1px #cecece;
    justify-content: space-between;
    align-items: flex-end;
}

.EventRow .Header > DIV.Timestamp DIV {
    overflow: hidden;
    text-overflow: ellipsis;
}

.EventRow .Header > DIV.Data {
    padding-right: 10px;
    width: 100%;
}
