.react-flow__handle {
    width: 17px !important;
    height: 17px !important;
    background-color: white !important;
    border-radius: 100% !important;
    position: unset !important;
    border: 2px solid #1565c0;
    transform: none !important;
    display: flex;
    align-items: center;
}

.react-flow__handle-bottom {
    bottom: -6px !important;
}

.react-flow__handle-top {
    top: -6px !important;
}

.react-flow__node-default.selected .NodePanel {
    box-shadow: 0 0 0 0;
    cursor: grab;
    background-color: rgb(0, 105, 192);
    color: white;
}

.react-flow__node .NodePanel {
    color: #333;
    text-transform: none;
    z-index: 10;
    position: relative;
}
.react-flow__node-flowNodeWithEvents.selected .NodePanel,
.react-flow__node-startNode.selected .StartNode,
.react-flow__node-condNode.selected .CondNode,
.react-flow__node-flowNode.selected .NodePanel,
.react-flow__node-flowNode.selected .NodePanelPassValue,
.react-flow__node-flowNode.selected:hover .NodePanel{
    cursor: grab;
    background-color: rgb(0, 105, 192);
    color: white;
    border: solid 2px rgb(0, 105, 192);
}

DIV.NodePorts {
    width: 100%;
    height: 17px;
    display: flex;
    justify-content: space-around;
}

DIV.CondNodePorts {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

DIV.NodePorts DIV.NodePortContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    z-index: 11;
}

DIV.NodePortContainer .PortHint {
    background-color: aliceblue;
    color: #444;
    border-radius: 3px;
    padding: 3px 8px;
    font-size: 80%;
    margin: 4px;
}

DIV.NodePortContainer .InputPortHint {
    top: -7px;
    transform: translate(0, -100%);
    position:absolute;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    animation-duration: .2s;
}

DIV.NodePortContainer .InputPortDesc {
    top: -33px;
    position:absolute;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    animation-duration: .3s;
    transform: translate(0,-100%);
    width: 160px;
}

DIV.NodePortContainer .OutputPortHint {
    bottom: -7px;
    position:absolute;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    animation-duration: .2s;
    transform: translate(0,100%)
}

DIV.NodePortContainer .OutputPortDesc {
    bottom: -31px;
    position:absolute;
    width: 160px;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    animation-duration: .3s;
    transform: translate(0,100%);
}

DIV.NodePanel {
    display: flex;
    flex-direction: row;
    border-style: solid;
    border-radius: 10px;
    border-width: 2px;
    background-color: white;
    position: relative;
    color: #444444;
}

DIV.NodePanelPassValue {
    display: flex;
    flex-direction: row;
    border-style: solid;
    border-width: 2px 6px 2px 6px;
    background-color: white;
    position: relative;
    color: #444444;
}

DIV.StartNode {
    display: flex;
    flex-direction: row;
    border-style: solid;
    border-radius: 50%;
    border-width: 2px;
    background-color: white;
    position: relative;
    color: #444444;
}

DIV.CondContainer {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
}

DIV.CondContainer:hover DIV.CondTitle {
    display: flex !important;
    align-items: center;
}

DIV.CondNode {
    background: #fff;
    height: 40px;
    text-align: center;
    transform:rotate(-45deg);
    width:40px;
    border-style: solid;
    border-width: 2px;
    position: relative;
    color: #444444;
    border-radius: 10px;
}

DIV.LeftPlaceholder,
DIV.RightPlaceholder {
    width: 1px;
    height: 50px;
    position: relative;
}

DIV.CondTitle {
    background-color: whitesmoke;
    padding: 0px 5px;
    border-radius: 6px;
    width: max-content;
    font-size: 12px;
    max-width: 200px;
    position: absolute;
    bottom: 0;
    top: 0;
    display: none;
    margin: auto 0;
    align-items: center;
}

DIV.NodePadding {
    padding: 5px;
    width: inherit;
    display: flex;
    align-items: center;
}

DIV.NodePro {
    display: flex;
    color: white;
    align-items: center;
    background-color: #333;
    padding: 1px 10px;
    border-radius: 0 6px 6px 0;
}

DIV.DebugNode {
    background-color: #ad1457 !important;
    border-color: #ad1457 !important;
    color: white !important;
}

DIV.NodeLabel {
    display: flex;
    align-items: center;
    overflow: hidden;
    flex-direction: row;
    font-size: 14px;
}

DIV.NodeLabel > P {
    margin: 0;
}

DIV.NodeLabel ASIDE {
    font-size: 70%;
    margin-left: 5px;
}

DIV.NodeOutboundEvents {
    display: flex;
    flex-direction: column
}

DIV.NodeInboundEvents {
    display: flex;
    flex-direction: column;
    justify-items: flex-end;
    align-items: flex-end;
    margin-right: 5px;
    position: absolute;
    right: 0;
}

DIV.NodeInboundEvents SPAN.Event,
DIV.NodeOutboundEvents SPAN.Event {
    display: inline-flex;
    font-size: 10px;
    border-radius: 3px;
    color: #444;
    margin: 1px 0 1px 5px;
    padding: 1px 10px 1px 5px;
    background-color: aliceblue;
    width: fit-content;
    cursor: pointer;
    white-space: nowrap;
}

DIV.NodeInboundEvents SPAN.Event:hover,
DIV.NodeOutboundEvents SPAN.Event:hover {
    background-color: #ddd;
}
