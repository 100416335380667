.DataBrowsingList {
    display: flex;
    flex-direction: column;
}

.DataBrowsingList > .Chart {
    height: 200px;
}

.DataBrowsingList > .Data {
}

