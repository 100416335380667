.SquareCard {
    display: inline-flex;
    align-items: center;
    border: solid rgba(225,225,225, 0.8) 1px;
    background: rgba(200,200,200,0.2);
    cursor: pointer;
    width: 190px;
    height: 190px;
    max-height: 200px;
    margin: 6px;
    padding: 15px;
    border-radius: 10px;
    flex-direction: column;
    justify-content: center;
    font-size: 120%;
    font-weight: 400;
    position: relative;
}

.SquareCard:hover {
    border: solid #ccc 1px;
    background: rgba(200,200,200,0.1);;
}

.SquareCard .name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    font-size: 110%;
    text-align: center;
}

.SquareCard .desc {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    font-size: 70%;
    text-align: center;
    max-height: 60px;
    opacity: 80%;
}

.SquareCard .tags {
    font-size: 80%;
}

.SquareCard .icon {
    margin: 10px;
    opacity: 80%;
}

.SquareCard .enabled {
    border-bottom: #00c853 solid 7px;
}

.SquareCard .disabled {
    border-bottom: #d81b60 solid 7px;
}